import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  email:null,
  TokenForR:null,
  comHash:null,
};

export const authSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    initalReg: (state, action) => {
      state.email= action.payload.email;
      state.TokenForR= action.payload.TokenForR;
      state.comHash= action.payload.comHash;
    },
    logout: (state, action) => {
     
    },
  },
})

// Action creators are generated for each case reducer function
export const { initalReg,logout } = authSlice.actions;

export default authSlice.reducer;