import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import logo from '../assets/images/logo.png'
import adminimg from '../assets/images/admin-img.jpg';
import { useSelector, useDispatch } from 'react-redux';
import { logoutStudent, teacherLogout, getAllCourseType, BASE_URL } from '../api/index';
import { logout } from '../store/authSlice';
import toast from 'react-hot-toast';

export default function Header1() {

  const [showMore, setShowMore] = useState(false)
  const [load, setLoad] = useState(true)
  const { isAuth, user } = useSelector((state) => state.auth);
  const [d, setd] = useState([]);
  const [f, setf] = useState([]);
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const router = useLocation();

  async function LogoutS(e) {
    e.preventDefault();
    try {
      const { data } = await logoutStudent();
      console.log(data)
      if (!data.status) {
        if (data.code === 400) {
          toast.error(data.message)
          localStorage.clear()
          Navigate("/")
          return;
        }
      } else {
        localStorage.clear();
        Navigate("/");
        return dispatch(logout());
      }
    } catch (error) {
      console.log(error);
    }
  }


  async function LogoutT(e) {
    e.preventDefault();
    try {
      const { data } = await teacherLogout();
      if(!data.status){
        if(data.code===400){
          toast.error(data.message)
        }
        return dispatch(logout());
      }else{
        localStorage.clear();
        Navigate("/teacher/signin")
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const result = await getAllCourseType();
        if(!result.data.status){
          toast.error(result.data.message)
        }else{
          setf(result.data.more_course);
          setLoad(false)
          localStorage.setItem('course_card',JSON.stringify(result.data.course_card))
          setd(result.data.six_course);
        }
      } catch (error) {
        console.log(error);
      }
    })();
    if(localStorage.getItem('token')==='undefined'){
      localStorage.clear()
      window.location.reload()
    }
  }, [])




  return (
    <>
      <div>
        <header className="Topheader fixed-top" data-scrollto-offset="0">
          <div className="container">
            <div className='navbar-styles'>
              <div>
                <NavLink to="/"><img src={logo} alt='profile' className='headerImg' /></NavLink>
              </div>
              <div className='navBarFirst ms-auto me-auto'>

                <div>
                  <nav id="navbar" className="navbarItems mt-1">
                    <ul>
                      <li><NavLink to="/">Home</NavLink ></li>
                      <li><NavLink to="/about-us">About Us</NavLink ></li>
                      <li><NavLink to="/school">Schools</NavLink ></li>
                      <li><NavLink to="/business">Business</NavLink ></li>
                      <li><NavLink to="/contact-us">Contact</NavLink ></li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div style={{ textAlign: 'center' }}>
                <div className="login_btns" style={{ marginTop: '3px' }}>
                  {localStorage.getItem('token') ?
                    <>
                      {user.role === 'teacher' ?
                        <div className="nav-dropdown">
                          <button className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                            {user?.name ?? localStorage.getItem('name')}
                            <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.5 1.25L6.25 6.5L1 1.25" stroke="#1D1E3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            {user.profilePic === undefined ?
                              <img src={BASE_URL + localStorage.getItem('profilePic')} alt='profile' />
                              :
                              <img src={BASE_URL + user.profilePic} alt='profile' />
                            }
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                            <li><a className="dropdown-item" href="/teacher/dashboard" onClick={(e) => {
                              e.preventDefault();
                              Navigate('/teacher/dashboard');
                            }}>Dashboard</a></li>
                            <li><a className="dropdown-item" href="/teacher/profile" onClick={(e) => {
                              e.preventDefault();
                              Navigate('/teacher/profile');
                            }}>My Profile </a></li>
                            <li><a className="dropdown-item" href="#" onClick={LogoutT}>Log Out </a></li>
                          </ul>
                        </div>
                        :
                        <>
                          <div className="nav-dropdown">
                            <button className="dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false" >
                              {user?.name ?? localStorage.getItem('name')} <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.5 1.25L6.25 6.5L1 1.25" stroke="#1D1E3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                              {user.profilePic === undefined ?
                                <img src={BASE_URL + localStorage.getItem('profilePic')} alt='profile' />
                                :
                                <img src={BASE_URL + user.profilePic} alt='profile' />
                              }
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                              <li>
                                <a className="dropdown-item" href="/student/dashboard" onClick={(e) => {
                                  e.preventDefault();
                                  Navigate('/student/dashboard');
                                }}>Dashboard </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="/student/profile" onClick={(e) => {
                                  e.preventDefault();
                                  Navigate('/student/profile');
                                }}>My Profile </a>
                              </li>
                              <li><a className="dropdown-item" href='#' onClick={LogoutS}>Log Out </a></li>
                            </ul>
                          </div>
                        </>
                      }
                    </>
                    :
                    <>
                      {router.pathname === '/student/signin' ?
                        <NavLink to="/student/signup">
                          <button className="newDesignBtn">Sign Up</button>
                        </NavLink>
                        : router.pathname === '/student/signup' ?
                          <NavLink to="/student/signin">
                            <button className="newDesignBtn">Login</button>
                          </NavLink>
                          : router.pathname === '/teacher/signin' ?
                            <div className='userWelcome'>
                              <i style={{ fontSize: "30px" }} className="bi bi-person-check"></i>
                            </div>
                            :
                            <NavLink to="/student/signin">
                              <button className="newDesignBtn">Login</button>
                            </NavLink>
                      }
                    </>}


                </div>

              </div>
            </div>



          </div>
        </header>
      </div>
      {router.pathname === "/" ?
        <div className='header_2' >
          <div className='header2'>
            <ul>
              <li><NavLink to="/all-courses?search&filter=relevance">All Courses</NavLink ></li>
              {load ?
                <>
                  <li>Real Estate</li>
                  <li>Credit</li>
                  <li>Budgeting</li>
                  <li>Cryptocurrency</li>
                  <li>Investing</li>
                  <li>Taxes</li>
                </>
                :
                <>
                  {d.map((element, index) => (
                    <li style={{ textTransform: 'capitalize' }} key={index + 1}><NavLink to={`/courses/${element.name}?search&filter=relevance`}>{element.name.replaceAll('-', ' ')}</NavLink></li>
                  ))}
                </>
              }
              <>{f.length > 0 ? <li className="dropdown">
                <div id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  More&nbsp;&nbsp;<i className='bi bi-chevron-down' />
                </div>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  {f.map((element, index) => (
                    <div className="dropdown-item" style={{ textTransform: 'capitalize' }} key={index + 1}>
                      <NavLink to={`/courses/${element.name}?search&filter=relevance`}>{element.name.replaceAll('-', ' ')}</NavLink>
                    </div>
                  ))}
                </div>
              </li> : null}
              </>
            </ul>
          </div>
        </div> : null}



      {/* for mobile */}

      <div className='mobile'>
        <div className='container'>
          <div className='mobile-navbar'>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <a data-bs-toggle="offcanvas" href="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop"><i className="bi bi-list" style={{ fontSize: '24px', color: 'grey' }}></i></a>
              <div><NavLink to="/"><img src={logo} alt='profile' className='headerImg' /></NavLink></div>
            </div>
            <div>
              {isAuth ?
                <>
                  {user.role === 'student' ?
                    <NavLink to="/all-courses?search&filter=relevance">
                      <i className="bi bi-book-half" style={{ fontSize: "20px", color: "#20776e" }}></i>
                    </NavLink>
                    :
                    <NavLink to="/all-courses?search&filter=relevance">
                      <i className="bi bi-book-half" style={{ fontSize: "20px", color: "#20776e" }}></i>
                    </NavLink>
                  }
                </>
                :
                <>
                  {router.pathname === '/student/signin' ?
                    <NavLink to="/student/signup">
                      <div className='mobile-login'>
                        Sign Up
                      </div>
                    </NavLink>
                    : router.pathname === '/student/signup' ?
                      <NavLink to="/student/signin">
                        <div className='mobile-login'>
                          Login
                        </div>
                      </NavLink>
                      : router.pathname === '/teacher/signin' ?
                        <i style={{ fontSize: "27px", color: '#20776e' }} className="bi bi-person-check"></i>
                        :
                        <NavLink to="/student/signin">
                          <div className='mobile-login'>
                            Login
                          </div>
                        </NavLink>
                  }
                </>
              }

            </div>
          </div>
        </div>
      </div>
      <div className="offcanvas offcanvas-start mobile-offcanvas" tabindex="-1" id="offcanvasWithBackdrop" aria-labelledby="offcanvasWithBackdropLabel">
        <div className="offcanvas-header">
          <div className='userWelcome'>
            {isAuth ?
              <>
                Hey Welcome <br />
                <b>
                  {user?.name ?? ''}
                </b>
              </>
              :
              'MyMomentous'
            }
          </div>
          <i className="bi bi-x-lg" style={{ fontSize: '20px', color: 'grey' }} data-bs-dismiss="offcanvas" aria-label="Close"></i>
        </div>
        <hr style={{ margin: 0 }} />
        <div className="offcanvas-body">
          <div className='mobile-sidebar'>
            <div className='navContent'>
              <ul>
                <li data-bs-dismiss="offcanvas" aria-label="Close"><NavLink to="/">Home</NavLink ></li>
                <li data-bs-dismiss="offcanvas" aria-label="Close"><NavLink to="/about-us">About</NavLink ></li>
                <li data-bs-dismiss="offcanvas" aria-label="Close"><NavLink to="/school">School</NavLink ></li>
                <li data-bs-dismiss="offcanvas" aria-label="Close"><NavLink to="/business">Business</NavLink ></li>
                <li data-bs-dismiss="offcanvas" aria-label="Close"><NavLink to="/contact-us">Contact</NavLink ></li>
                {isAuth ?
                  <>
                    {user.role === 'student' ?
                      <>
                        <li data-bs-dismiss="offcanvas" aria-label="Close"><NavLink to="/student/dashboard">Dashboard</NavLink></li>
                        <li data-bs-dismiss="offcanvas" aria-label="Close"><NavLink to="/student/profile">Profile</NavLink></li>
                      </>
                      :
                      <>
                        <li data-bs-dismiss="offcanvas" aria-label="Close"><NavLink to="/teacher/dashboard">Dashboard</NavLink></li>
                        <li data-bs-dismiss="offcanvas" aria-label="Close"><NavLink to="/teacher/profile">Profile</NavLink></li>
                      </>
                    }
                  </>
                  : null}
                <li data-bs-dismiss="offcanvas" aria-label="Close"><NavLink to="/all-courses?search&filter=relevance">All Courses</NavLink ></li>

              </ul>
            </div>
            <div className='navContent'>
              <ul>
                {router.pathname === "/" ?
                  <>
                    {d.map((element, index) => (
                      <li style={{ textTransform: 'capitalize' }} key={index + 1} data-bs-dismiss="offcanvas" aria-label="Close"><NavLink to={`/courses/${element.name}?search&filter=relevance`}>{element.name.replaceAll('-', ' ')}</NavLink></li>
                    ))}
                    {f.length > 0 ?
                      <>
                        {!showMore ? <li onClick={() => setShowMore(true)}>More&nbsp;&nbsp;<i className='bi bi-chevron-down' /></li> : <li onClick={() => setShowMore(false)}>Less&nbsp;&nbsp;<i className='bi bi-chevron-up' /></li>}
                      </> : null}
                    {showMore && (
                      <>
                        {f.map((element, index) => (
                          <li style={{ textTransform: 'capitalize' }} key={index + 1} data-bs-dismiss="offcanvas" aria-label="Close"><NavLink to={`/courses/${element.name}?search&filter=relevance`}>{element.name.replaceAll('-', ' ')}</NavLink></li>
                        ))}
                      </>
                    )}
                  </> : null}
                {isAuth ?
                  <>
                    {user.role === "teacher" ?
                      <li onClick={LogoutT} data-bs-dismiss="offcanvas" aria-label="Close">Logout&nbsp;&nbsp;<i style={{ fontSize: "19px" }} className="bi bi-box-arrow-right"></i></li>
                      :
                      <li onClick={LogoutS} data-bs-dismiss="offcanvas" aria-label="Close">Logout&nbsp;&nbsp;<i style={{ fontSize: "19px" }} className="bi bi-box-arrow-right"></i></li>
                    }</>
                  : null}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


