import React from 'react'
import BarLoader from "react-spinners/BarLoader"
import logo from "../../assets/images/logo.png"

const HomeLazy = () => {
  return (
    <div style={{ height: '100vh',flexDirection:"column",gap:"10px", display: "flex", justifyContent: 'flex-start',paddingTop:'230px', alignItems: "center" }}>
      <img src={logo} style={{width:"130px"}}/>
      <BarLoader
        color="#20776e"
        height={2}
        speedMultiplier={1.5}
        width={200}
      />
    </div>
  )
}

export default HomeLazy
