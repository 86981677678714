import React from 'react'
import footerlogo from '../assets/images/footer-logo.svg'
import Modal from '../home/Modal';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, } from 'react-redux';

export default function Footer() {

  const router = useLocation()
  const Navigate = useNavigate();
  const { isAuth, } = useSelector((state) => state.auth);

  return (
    <>
      {router.pathname === '/contact-us' ? null :
        <>
          <div id="footer" class="footer">
            <div class="footer-content">
              <div class="container">
                <div class="row">

                  <div class="col-lg-4 col-md-6">
                    <div class="footer-info">
                      <a href="#"><img src={footerlogo} alt="footerlogo" className='footerLogo' /></a>
                      <p>
                        "Let's elevate your education and boost your financial literacy. We're here to help you stay focused during your courses."
                      </p>
                      <div class="social-links order-first order-lg-last mb-3 mb-lg-0">
                        <a href="https://www.instagram.com/" class="instagram"><i style={{ fontSize: "25px" }} class="bi bi-instagram"></i></a>
                        <a href="https://www.facebook.com/" class="facebook"><i style={{ fontSize: "25px" }} class="bi bi-facebook"></i></a>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-6 footer-links">
                    <h4>Company</h4>
                    <ul>
                      <li><NavLink to="/">Home</NavLink></li>
                      <li><NavLink to="/podcast">Podcasts</NavLink></li>
                      <li><NavLink to="/all-courses?search=&filter=relevance">Courses</NavLink></li>
                    </ul>
                  </div>

                  <div class="col-lg-3 col-md-6 footer-links">
                    <h4>Solution</h4>
                    <ul>
                      {
                        !isAuth &&
                        <li>
                          {router.pathname === "/teacher/signin" ?
                            <NavLink to="/student/signin">Become a Student</NavLink>
                            :
                            <NavLink to="/teacher/signin">Become a Tutor</NavLink>
                          }
                        </li>
                      }
                      <li> <NavLink to="/contact-us">Contact us</NavLink></li>
                      <li> <NavLink to="/testimonial">Testimonials</NavLink></li>
                    </ul>
                  </div>

                  <div class="col-lg-2 col-md-6 footer-links">
                    <h4>Legal</h4>
                    <ul>
                      <li> <NavLink to="/privacy">Privacy</NavLink></li>
                      <li> <NavLink to="/terms-and-conditions">Terms</NavLink></li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
            <div class="footer-legal text-center">
              <div class="container d-flex flex-column  align-items-center">
                <div class="d-flex flex-column align-items-center">
                  <div class="copyright">
                    &copy; 2023 MyMomentous-Finance. All rights reserved.
                  </div>
                  <div class="credits">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal />
        </>
      }
    </>
  )
}
