import { configureStore } from '@reduxjs/toolkit';

import authReducer from './authSlice';
import editSlice from './editSlice';
import registerSlice from './registerSlice';

export const store = configureStore({
  reducer: {
    auth:authReducer,
    register:registerSlice,
    editcourse : editSlice
  },
});
