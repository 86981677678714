import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    name: '',
    image: '',
    plevel: 'beginner',
    shortDes: '',
    courseType: '',
    coursemodules: []
};

export const editSlice = createSlice({
    name: 'edit',
    initialState,
    reducers: {
        setSlice: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        addModule: (state, action) => {
            return {
                ...state,
                coursemodules: [...state.coursemodules, action.payload]
            }
        },
        moduleUpdate: (state, action) => {
            let filtered = state.coursemodules.filter(item => (item.tempid !== action.payload.id) || (item._id !== action.payload.id))
            console.log({
                    ...state,
                    coursemodules: [...filtered, action.payload.data]
                }," this is oiinoinoi")
            // return {
            //     ...state,
            //     coursemodules: [...filtered, action.payload.data]
            // }
            return {}
        }
    },
})

// Action creators are generated for each case reducer function
export const { setSlice, addModule, moduleUpdate } = editSlice.actions;

export default editSlice.reducer;