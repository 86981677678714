import './App.css';
import { useEffect,  lazy, Suspense } from 'react';
import Header from './home/Header';
import Footer from './home/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import $ from "jquery";
import 'owl.carousel/dist/owl.carousel';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './assets/main.css';
import { BrowserRouter as Router } from 'react-router-dom';
// import Linking from './Linking';
import { Toaster } from 'react-hot-toast';
import { refreshStudent, teacherrefresh } from './api/index';
import { useDispatch } from 'react-redux';
import { login } from './store/authSlice';
import Modal from './home/Modal';
import 'react-toastify/dist/ReactToastify.css';
import Header1 from './home/Header1';
import HomeLazy from './home/comp/HomeLazy';

const Linking = lazy(() => import('./Linking'));

function App() {

  const dispatch = useDispatch();


  async function mystrefresh() {
    try {
      const { data } = await refreshStudent();
      if (!data.status) {
        if (data.code == 400) {
          localStorage.clear();
        }
      }else{
        localStorage.setItem('token', data.accesstoken);
        localStorage.setItem('role', data.result.role);
        const { _id, name, role, isActiviated, profilePic, isBlock, } = data.result;
        return dispatch(login({ _id, name, role, isActiviated, profilePic, isBlock }));
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  async function myteacherref() {
    try {
      const { data } = await teacherrefresh();
      if (!data.status) {
        if (data.code == 400) {
          localStorage.clear();
        }
      }else{
        localStorage.setItem('token', data.accesstoken);
        localStorage.setItem('role', data.result.role);
        const { _id, name, role, isActiviated, profilePic, isBlock, } = data.result;
        return dispatch(login({ _id, name, role, isActiviated, profilePic, isBlock }));
      }
    } catch (error) {
      console.log(error.message)
    }

  }

  useEffect(() => {
    require('./assets/main');
    require('./assets/bootstrap-icons.css');
    require('bootstrap/dist/css/bootstrap.min.css');
    require('bootstrap/dist/js/bootstrap.bundle.min');
    AOS.init();
    //call the refresh functions
    mystrefresh();
    myteacherref();
  }, [])

  return <>
    <Router>
      {/* <Header /> */}
      <Suspense fallback={<HomeLazy />}>
      <Header1 />
      <Linking />
      <Footer />
      </Suspense>
      <Modal></Modal>
    </Router>
    <Toaster toastOptions={{
      duration: 1000,
      style: {
        padding: '13px',
        width: "100%",
        textAlign:'center',
        fontSize: '14px',
        lineHeight: "20px",
        color: 'black',
        fontWeight: "500"
      }
    }} />
  </>
}




export default App;
