import axios from "axios";

const BASE_URL = 'https://www.mymomentous.com/';
// const BASE_URL = 'http://localhost:5000/';
// const BASE_URL = "https://ea3e-2401-4900-1c6f-b6a3-6b2-ad61-a2ed-cb08.ngrok-free.app/"

const instance = axios.create({
    baseURL: BASE_URL,
    headers: { ContentType: "application/json", },
    withCredentials: true,
});

const config = {
    headers: { "Content-Type": "multipart/form-data" },
};

//student api
export const registerStudent = async (data) => {

    return instance.post('/students/register', data, config);
};

export const verifyStudent = async (data) => {
    return instance.post('/students/verify', data);
};

export const RegisterStudentLastStep = async (data) => {

    return instance.post('/students/s15', data, config);
}

export const loginStudent = async (data) => {

    return instance.post('/students/login', data);
};

export const findStudentTestmonialForPage = async () => {
    return instance.get('/home/testimonials-2');
}

export const logoutStudent = async () => {

    return instance.get('/students/logout');
};

export const refreshStudent = async () => {
    return instance.get('/students/refresh-student');
}

export const getDataofStudentForActiviate = async () => {
    return instance.get('/students/s14');
}

export const StudentForActiviate = async (data) => {
    return instance.post('/students/update-student-details', data, config);
}

//forogot password //forgot password page
export const ForgotStudent = async (data) => {

    return instance.post('/students/forgot-password', data);
}

//s6

export const getStudentProfile = async () => {
    return instance.get('/students/get-student-profile');
}

//update student profile /s5

export const updateStudentProfile = async (data) => {
    return instance.post('/students/s5', data, config);//FromfileNameFeild:'profilePic'
}

// student register a course 
export const studentPurchaseACourse = async (data) => {
    return instance.post('/students/purchase-course', data);
}



//student all purchase list  not completed
export const findAllStudentCoursePurchase = async (skip) => {
    return instance.get('/students/purchased-courses?skip=' + skip);
}
//findAllStudentCoursePurchaseCompleted
export const findAllStudentCoursePurchaseCompleted = async (skip) => {
    return instance.get('/students/completed-courses?skip=' + skip);
}


//student Single Purchase By Id  
export const findSingleStudentPurchaseBasedOnId = async (id) => {
    return instance.get(`/students/purchased-course-details/${id}`);
}
//updateStudentPurchaseModuleIndex  
export const updateStudentPurchaseModuleIndex = async (data) => {
    return instance.post('/students/update-module-index', data)

}

//updateStudentPurchaseProgressLevel  
export const updateStudentPurchaseProgressLevel = async (data) => {
    return instance.post('/students/update-progress-level', data);

}

//studentFinalCompletePurchaseCourse
export const studentFinalCompletePurchaseCourse = async (data) => {
    return instance.post('/students/final-purchase-course', data);
}

//RegisterCourseRatingByStudent
export const RegisterCourseRatingByStudent = async (data) => {
    return instance.post('/students/submit-rating', data);
}
//findQuestionForStudentPurchase
export const findQuestionForStudentPurchase = async (data) => {
    return instance.get('/students/course-quiz', data);

}

//registerStudentQuiz
export const registerStudentQuiz = async (data) => {
    return instance.post('/students/submit-quiz', data);
}

//StudentTeacherProfileAndCourse'
export const StudentTeacherProfileAndCourse = async (id) => {
    return instance.get(`/students/student-teacher-profile/${id}`);
}


export const getTeacherCourses = async (id,skip) => {
    return instance.get('/students/teacher-courses/'+id+'?skip='+skip);
}


//findStudentPurchasedBasedOnCourseId
export const findStudentPurchasedBasedOnCourseId = async (data) => {
    return instance.post('/students/find-student-course', data);

}


//teacher api 

export const teacherLogin = async (data) => {
    return instance.post('/teacher/login-teacher', data);
}
// teacher logout
export const teacherLogout = async () => {
    return instance.get('/teacher/logout-teacher');
}

// teacher Refresh 
export const teacherrefresh = async () => {
    return instance.get('/teacher/refresh-teacher');

}
//teacher update the profile
export const teacherActiviate = async (data) => {
    return instance.post('/teacher/update-teacher', data, config);
}
//teacher Profile
export const teacherProfile = async () => {
    return instance.get('/teacher/get-teacher-profile');
}
//teacher reset password
export const teacherForgot = async (data) => {

    return instance.post('/teacher/t13', data);
}

// for uploading the course first step
export const uploadCourseFirstStep = async (data) => {
    return instance.post('/teacher/upload-course', data)
}

// for uploading the course first step
export const uploadCourseSecondStep = async (data) => {
    return instance.post('/teacher/upload-course-quiz', data)
}

//guide for teacher findAllGuideListForCourse
export const findAllGuideListForCourse = async () => {
    return instance.get('/teacher/all-guides-video');
}


// findAllCourseOfTeacherUploaded
export const findAllCourseOfTeacherUploaded = async (skip) => {
    return instance.get('/teacher/find-all-course-of-teacher?skip=' + skip);
}

//findLastSubmissionOfTeacherCourse
export const findLastSubmissionOfTeacherCourse = async () => {

    return instance.get('/teacher/find-last-submission-teacher');
}

//findSingleCourseOfTeacherById
export const findSingleCourseOfTeacherById = async (id) => {
    return instance.get('/teacher/find-teacher-course-details/' + id);
}

//findStudentWhoBroughtTheCourseFromTeacherSide
export const findStudentWhoBroughtTheCourseFromTeacherSide = async (id, skip) => {
    return instance.get('/teacher/find-student-who-buy-course/' + id + '?skip=' + skip);
}

//edit or delete course /t19
export const techerDeleteCourse = async (id) => {
    return instance.get('/teacher/delete-course/' + id);
}

//get data for edit getdataForEditCourseBasedOnId
export const getdataForEditCourseBasedOnId = async (id) => {
    return instance.get('/teacher/get-course-details/' + id);
}

//updateCourseBasedOnId
export const updateCourseBasedOnId = async (data) => {
    return instance.post('/teacher/update-course-details', data)
}

//updateCourseBasedOnId quiz updateCourseBasedQuizOnId
export const updateCourseBasedQuizOnId = async (data) => {
    return instance.post('/teacher/update-course-by-quiz', data)
}

//find all 
export const findAllSepcialityForTeacher = async () => {

    return instance.get('/teacher/specaility-teacher');
}


//home routes register suscribe
export const registerSuscribe = async (data) => {

    return instance.post('/home/student-subscribed', data);
}
//Data Caluation
export const calculateViewsStudentCertificateData = async () => {
    return instance.get('/home/calculate-student-certificate-data');
}
//CourseType
export const getAllCourseType = async () => {
    return instance.get('/home/get-course-category');
}

// top list for home page ->Home.js //10 lastest course 
export const searchCourseForHomePage = async () => {
    return instance.get('/home/four-course-card-home');

}


// top list for home page ->Home.js //10 lastest course 
export const searchCourseForHomePageBySorting = async (data) => {

    return instance.get(`/home/search-all-courses${data}`);
}

//testmoinal 
export const findStudentTestmonialForHome = async () => {
    return instance.get('/home/student-testimonials');
}

//all cousre page
//findAllCourseListForSearchPage
export const findAllCourseListForSearchPage = async (data) => {
    return instance.get('/home/h20' + data);
}
//

export const findAllCourseListForSearchPageBySorting = async (data) => {

    return instance.get(`/home/h22${data}`);

}


//findTopTeachersBasedOnRating
export const findTopTeachersBasedOnRating = async () => {
    return instance.get('/home/top-instructor');
}




//Faq
export const getAllFaqSection = async () => {
    return instance.get('/home/get-all-faq');
}

//disclaimer
export const findDisclaimerForHome = async () => {

    return instance.get('/home/get-desclaimer');
}

//privacy policy
export const findPrivacyPolicyForHome = async () => {

    return instance.get('/home/get-privacy');
}

// terms&conditions
export const findTermsConditionForHome = async () => {
    return instance.get('/home/get-term-condition');
}


//course detail Page Api 
export const getCourseDetails = async (id) => {
    return instance.get('/home/course-details/' + id)
}

//findStudentBroughtPurchaseCourse
export const findStudentBroughtPurchaseCourse = async (data) => {
    return instance.get(`/home/student-also-purchased-course${data}`);
}

//findAllCousreLevel
export const findAllCousreLevel = async () => {
    return instance.get('/a/a19');
}

//findAllTeacherSepcailityLevel
export const findAllTeacherSepcailityLevel = async () => {
    return instance.get('/a/a20');
}

//findAllCourseTypeForCourseUpload

export const findAllCourseTypeForCourseUpload = async () => {
    return instance.get('/home/all-course-category');
}

//extra content module 
//resource
export const findResourceForHome = async () => {
    return instance.get('/home/get-resource');
}

//claim
export const findClaimForHome = async () => {

    return instance.get('/home/get-claim');
}

//contact us from not done 
export const RegisterContactFormForHome = async (data) => {
    return instance.post('/home/contact-us-form', data);
}

//low
//app.get('/home28/low',CourseSearchController.findAllCourseWithLowFilter);
export const findAllCourseWithLowFilter = async (data) => {
    return instance.get(`/home/find-course-by-low/low${data}`);

}

//high
//app.get('/home29/high',CourseSearchController.findAllCourseWithHighFilter);
export const findAllCourseWithHighFilter = async (data) => {

    return instance.get(`/home/h29/high${data}`);
}




//Course Flag
//flag  app.post('/add-course-flag',);
export const registerACourseFlag = async (data) => {

    return instance.post('/students/add-course-flag', data);
}


//app.get('/check-flag');
export const findStudentCourseFlaged = async (data) => {

    return instance.get(`/students/check-flag${data}`);
}

//app.get('/check-flag-purchase');

export const findStudentCourseFlagedBasedOnPurchaseId = async (data) => {

    return instance.get(`/students/check-flag-purchase${data}`);
}


export default instance;

export { BASE_URL }



