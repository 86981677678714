import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isAuth: false,
  user: {
    role: "",
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isAuth = true;
      state.user = action.payload;
    },
    logout: (state, action) => {
      state.isAuth = false;
      state.user = {
        role: "",
      };
    },
    userupdateProfile:(state,action)=>{
      state.user=action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { login, logout,userupdateProfile } = authSlice.actions;

export default authSlice.reducer;

/**
 *_id:"llkl",
    name:"sanjay",
    role:"student",
    profilePic:heroImage,

 */