import React, {} from 'react'

export default function Modal() {


  return (
    <>
      <div class="modal fade course-popup" id="coursevideo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel"><span>Course Preview</span>Learn Python: The Complete Python Programming Course</h5>
            </div>
            <div class="modal-body">
              <iframe id="glassAnimalsVideo" src="https://www.youtube.com/embed/PhdtdUljThU" frameborder="0" allowfullscreen></iframe>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"> <span aria-hidden="true">&times;</span></button>
            </div>
          </div>
        </div>
      </div>
      
      <div class="modal fade course-popup" id="introductionpop" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel"><span>Welcome </span>Learn Python: The Complete Python Programming Course</h5>
            </div>
            <div class="modal-body">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/tPk1h2VZ2DI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"> <span aria-hidden="true">&times;</span></button>
            </div>
          </div>
        </div>
      </div>
      
    </>
  )
}
